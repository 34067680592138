<template>
  <v-container>
    <title-header :title="pageTitle" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <detail-header v-if="detail" :detail="detail" />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="7">
        <v-card>
          <detail-residue-list
            v-if="detail"
            :updateKey="registerKey"
            :residueTypeList="detail.residueTypes"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-card>
          <register-residue-subtype
            :key="`register-${registerKey}`"
            :residueTypeList="residueTypeList"
            @submit="handleSubmitRegisterResidueSubtype"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-wrap justify-end" cols="12">
        <v-btn
          :to="{
            name: 'demanufacturing-update',
            params: { id: batchId }
          }"
          color="secondary-color-1"
          class="ma-0 mt-1 next-step-button white--text"
          >Estocar Resíduo</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import DetailHeader from "@/modules/stock/demanufacturing/views/components/DetailHeader";
  import DetailResidueList from "@/modules/stock/demanufacturing/views/components/DetailResidueList";
  import RegisterResidueSubtype from "@/modules/stock/demanufacturing/views/components/RegisterResidueSubtype";

  import { mapActions } from "vuex";

  export default {
    name: "DetailDemanufacturing",

    components: {
      TitleHeader,
      DetailHeader,
      DetailResidueList,
      RegisterResidueSubtype
    },

    data: () => ({
      detail: undefined,
      residueTypeList: [],
      registerKey: 1,

      batchId: undefined
    }),

    mounted() {
      this.batchId = this.$route.params.id;
      this.fetchData();
      this.fetchResidueTypes();
    },

    computed: {
      pageTitle() {
        return `Registro Lote ${this.detail?.batchNumber}`;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),

      ...mapActions("recycler", ["getResidues"]),

      ...mapActions("stock/demanufacturing", [
        "getDemanufacturingById",
        "createDemanufacturingResidueSubtype"
      ]),

      async fetchResidueTypes() {
        try {
          const { data } = await this.getResidues();
          this.residueTypeList = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async fetchData() {
        try {
          const { data } = await this.getDemanufacturingById(this.batchId);
          this.detail = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async handleSubmitRegisterResidueSubtype(residueSubtypes) {
        try {
          const response = await this.createDemanufacturingResidueSubtype({
            id: this.batchId,
            body: {
              residueSubtypes
            }
          });

          this.toggleSnackbar({
            text: response?.message || "Registro criado com sucesso",
            type: "success"
          });

          await this.fetchData();

          this.registerKey++;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
