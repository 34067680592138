<template>
  <div>
    <template v-for="(item, index) in value">
      <residue-subtype-field
        dense
        v-model="item.value"
        :key="`residue-subtype-${index}`"
        :residueSubtypeList="residueSubtypeList"
        :index="index"
        :selectedIds="selectedResidueSubtypeIds"
        :canRemove="isNotLastItem(index)"
        @remove="() => handleRemoveItem(index)"
      />
    </template>
    <div style="width: 225px;">
      <p
        class="d-flex align-center"
        :class="{
          'secondary-color-1--text': isEveryItemValid,
          pointer: isEveryItemValid
        }"
        @click="handleAddItem"
      >
        <v-icon :color="isEveryItemValid ? 'secondary-color-1' : ''" size="21"
          >mdi-plus</v-icon
        >
        Adicionar subtipo de resíduo
      </p>
    </div>
  </div>
</template>

<script>
  import ResidueSubtypeField from "@/modules/stock/demanufacturing/views/components/ResidueSubtypeField";

  export default {
    name: "ResidueSubtypeContainer",

    props: {
      value: {
        type: Array,
        required: true
      },
      residueSubtypeList: {
        type: Array,
        required: true
      }
    },

    components: {
      ResidueSubtypeField
    },

    computed: {
      isEveryItemValid() {
        return this.everyValid();
      },
      selectedResidueSubtypeIds() {
        return this.value
          .map(item => item.value.residueSubtype?.id)
          .filter(x => x);
      }
    },

    methods: {
      handleAddItem() {
        if (!this.isEveryItemValid) {
          return;
        }
        this.$emit("input", [...this.value, { value: {} }]);
      },

      handleRemoveItem(index) {
        const copy = [...this.value];
        copy.splice(index, 1);
        this.$emit("input", copy);
      },

      isLastItem(index) {
        return index === this.value.length - 1;
      },

      isNotLastItem(index) {
        return !this.isLastItem(index);
      },

      everyValid() {
        return this.value.every(item => {
          return (
            item.value?.residueSubtype &&
            item.value?.weight &&
            this.residueSubtypeList.length !== this.value.length
          );
        });
      }
    }
  };
</script>
