<template>
  <validation-observer>
    <v-form @submit.prevent="handleSubmit" slot-scope="{ invalid }">
      <div class="mx-5 py-4">
        <h3>Cadastrar Tipo e Subtipo de Resíduo</h3>
      </div>
      <v-divider />
      <div class="mx-5 py-4">
        <residue-type-field
          v-model="selectedResidueType"
          :residueTypeList="residueTypeList"
          :disabled="disableResidueTypeField"
        />
        <residue-subtype-container
          v-if="residueSubtypeList.length"
          v-model="selectedResidueSubtypes"
          :residueSubtypeList="residueSubtypeList"
        />

        <div v-if="residueSubtypeList.length" class="d-flex justify-end">
          <v-btn
            type="submit"
            color="secondary-color-1"
            :disabled="invalid"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            >Salvar</v-btn
          >
        </div>
      </div>
    </v-form>
  </validation-observer>
</template>

<script>
  import ResidueTypeField from "@/modules/stock/demanufacturing/views/components/ResidueTypeField";
  import ResidueSubtypeContainer from "@/modules/stock/demanufacturing/views/components/ResidueSubtypeContainer";

  import { ValidationObserver } from "vee-validate";
  import { mapActions } from "vuex";

  export default {
    name: "RegisterResidueSubtype",

    props: {
      residueTypeList: {
        type: Array,
        required: true
      }
    },

    components: {
      ValidationObserver,
      ResidueTypeField,
      ResidueSubtypeContainer
    },

    data: () => ({
      selectedResidueType: undefined,
      selectedResidueSubtypes: [
        {
          value: {}
        }
      ]
    }),

    watch: {
      selectedResidueType(_newVal) {
        this.selectedResidueSubtypes = [
          {
            value: {}
          }
        ];
      }
    },

    computed: {
      residueSubtypeList() {
        return this.selectedResidueType?.residueSubtypes || [];
      },

      disableResidueTypeField() {
        return this.selectedResidueSubtypes.length > 1;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),

      handleSubmit() {
        this.$emit("submit", this.formatData());
      },

      formatData() {
        return this.selectedResidueSubtypes.map(selected => {
          return {
            weight: parseFloat(selected.value.weight),
            residueSubtypeId: selected.value.residueSubtype.id
          };
        });
      }
    }
  };
</script>

<style></style>
