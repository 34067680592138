<template>
  <v-row dense>
    <v-col cols="12" md="7">
      <validation-provider
        :rules="{ required: true }"
        :name="`residue-subtype-${index}`"
        v-slot="{ errors }"
      >
        <v-select
          v-model="value.residueSubtype"
          :items="list"
          :error-messages="errors[0]"
          item-text="description"
          label="Subtipo de resíduo *"
          placeholder="Ex: Proc. Cerâmico Tipo C1"
          outlined
          clearable
          return-object
        ></v-select>
      </validation-provider>
    </v-col>
    <v-col cols="10" :md="3">
      <weight-field
        :name="`residue-subtype-weight-${index}`"
        v-model="value.weight"
        label="Peso"
        placeholder="Ex: 1kg"
        :isRequired="true"
      />
    </v-col>
    <v-col cols="2" v-if="!canRemove">
      <div class="ma-3">
        <v-icon @click="$emit('remove')">mdi-close-circle-outline</v-icon>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import WeightField from "@/modules/core/views/components/WeightField";

  import { ValidationProvider } from "vee-validate";

  import { required } from "vee-validate/dist/rules";
  import { extend } from "vee-validate";

  extend("required", {
    ...required,
    message: "Esse campo é obrigatório"
  });

  export default {
    name: "ResidueSubtypeField",

    props: {
      value: {
        type: null,
        required: true
      },
      residueSubtypeList: {
        type: Array,
        required: true
      },
      canRemove: {
        type: Boolean,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      selectedIds: {
        type: Array,
        required: true
      }
    },

    components: {
      ValidationProvider,
      WeightField
    },

    data: () => ({
      list: []
    }),

    mounted() {
      this.initList();
    },

    updated() {
      this.initList();
    },

    methods: {
      compareResidueSubtype(residueSubtype, input) {
        return this.normalize(residueSubtype.description).includes(input);
      },

      normalize(value) {
        return value.toLowerCase();
      },

      initList() {
        this.list = this.residueSubtypeList.filter(residueSubtype =>
          this.checkSelectedResidueSubtype(residueSubtype)
        );
      },

      checkSelectedResidueSubtype(residueSubtype) {
        return (
          this.value?.residueSubtype?.id === residueSubtype.id ||
          !this.selectedIds.includes(residueSubtype.id)
        );
      }
    }
  };
</script>

<style lang="scss" scoped></style>
