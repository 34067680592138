<template>
  <div
    class="mx-6 py-5 d-flex flex-wrap justify-space-between"
    :class="{ 'flex-column': $isMobile }"
  >
    <p class="ma-0 black-1--text">Status de Coleta: {{ batch.status }}</p>
    <p class="ma-0 black-1--text">CRC: {{ batch.crc }}</p>
    <p class="ma-0 black-1--text">Peso Total: {{ batch.weightTotal }}</p>
    <p class="ma-0 black-1--text">
      Peso Desmanufaturado:
      {{ batch.weightTotalDemanufacture }}
    </p>
    <p class="ma-0 black-1--text">Peso Destinado: {{ batch.weightDestiny }}</p>
  </div>
</template>

<script>
  export default {
    name: "DetailHeader",

    props: {
      detail: {
        type: Object,
        required: true
      }
    },

    computed: {
      batch() {
        return {
          status: this.detail.statusCollectionRequest.description,
          crc: this.detail.crc,
          weightTotal: this.detail.screeningTotalWeight,
          weightTotalDemanufacture: this.detail.demanufacturedWeight,
          weightDestiny: this.detail.destinedWeight
        };
      }
    }
  };
</script>

<style lang="scss" scoped></style>
