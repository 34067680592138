<template>
  <validation-provider
    name="residue-type"
    :rules="{ required: true }"
    v-slot="{ errors }"
  >
    <h4 class="my-2">Tipo de resíduo:</h4>
    <v-select
      :value="value"
      @input="$emit('input', $event)"
      :error-messages="errors[0]"
      :items="residueTypeList"
      :disabled="disabled"
      item-text="description"
      placeholder="Ex: Placa de circuito"
      hide-no-data
      hide-selected
      outlined
      no-filter
      clearable
      return-object
    ></v-select>
  </validation-provider>
</template>

<script>
  import { required } from "vee-validate/dist/rules";
  import { extend } from "vee-validate";

  import { ValidationProvider } from "vee-validate";

  extend("required", {
    ...required,
    message: "Esse campo é obrigatório"
  });

  export default {
    name: "ResidueTypeField",

    props: {
      value: {
        type: null,
        required: true
      },
      residueTypeList: {
        type: Array,
        required: true
      },
      disabled: {
        type: Boolean,
        required: true
      }
    },

    components: {
      ValidationProvider
    }
  };
</script>

<style></style>
